var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"participant",attrs:{"id":"participant"}},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"content",staticStyle:{"margin-top":"30px"},attrs:{"dir":"ltr"}},[_c('vueper-slides',{ref:"vueperslides2",staticStyle:{"margin":"0px"},attrs:{"touchable":false,"bullets":false,"gap":2,"slide-multiple":"","breakpoints":{
                    700: { visibleSlides: 1, slideMultiple: 1 },
                    992: { visibleSlides: 2, slideMultiple: 2 },
                },"visible-slides":4,"fixed-height":"220px"},on:{"slide":function($event){_vm.$refs.vueperslides2 &&
                        _vm.$refs.vueperslides2.goToSlide(
                            $event.currentSlide.index,
                            { emit: false }
                        )}}},_vm._l((_vm.content),function(item,i){return _c('vueper-slide',{key:i,nativeOn:{"click":function($event){_vm.$refs.vueperslides2 &&
                            _vm.$refs.vueperslides2.goToSlide(i - 1)}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"participant_item",attrs:{"data-aos":"fade-up","data-aos-duration":"1800"}},[_c('div',{staticClass:"participant-item_link"},[_c('div',{staticClass:"participant-item_bg"}),_c('div',{staticClass:"participant-item_title"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"participant-item_decs"},[_vm._v(" "+_vm._s(item.decs)+" ")])])])]},proxy:true}],null,true)})}),1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center"},[_c('h2',{staticClass:"section-title"},[_vm._v(" لماذا يجب عليك استئجار مساحات ضمن مول هديتي الإفتراضي ")])])
}]

export { render, staticRenderFns }