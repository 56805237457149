<template>
    <v-card class="category-card">
        <div class="image-container">
            <img class="category-card-image" :src="getImage" alt="category image" />
        </div>
        <div class="title-container">
            <h6 class="category-card-title">{{ title }}</h6>
        </div>
    </v-card>
</template>


<script>
export default {
    props: ["title", "image"],
    components: {
    },
    data() {
        return {
        };
    },
    computed: {
        getImage() {
            return require("@/assets/img/" + this.image)
        }
    },
    methods: {
    },
    mounted() {
    },
}
</script>
<style>
.category-card{
    padding: 30px 20px 20px;
    height: 220px;
    border: 1px solid rgba(128, 128, 128, 0.192) !important;
    box-shadow: none !important;
    transition: all .5s;
}
.category-card:hover{
    box-shadow: 0 0 10px rgba(128, 128, 128, 0.308) !important;
    transition: all .5s;
}

.category-card-title{
    color: var(--v-primary-base);
    text-align: center;
}
.category-card-image{
    width: 50px;
    height: auto;
}
.category-card:before {
    content: '';
    width: 85px;
    height: 85px;
    border-radius: 50%;
    background-color: var(--v-accent-base);
    /* opacity: ; */
    position: absolute;
    top: calc(50% - 78px);
    left: calc(50% - 42.5px);
    transform-origin: center bottom;
    animation-fill-mode: both;
    animation-name: bounce;
    animation-timing-function: ease-out;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-delay: 0s;
}

.title-container{
    display: flex;
    justify-content: center;
    /* height: -webkit-fill-available; */
    height: 140px;
    align-items: center;
    z-index: 9999;
    position: relative;
}

.image-container{
    display: flex;
    justify-content: center;    
    align-items: center;
    z-index: 9999;
    position: relative;
}
@keyframes bounce {

0%,
20%,
53%,
80%,
100% {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

40%,
43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
}

70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    animation-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
}

90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
}
}

</style>