<template>
    <section class="participant" id="participant">
        <div class="container">
            <div class="row justify-content-center">
                <h2 class="section-title">
                    لماذا يجب عليك استئجار مساحات ضمن مول هديتي الإفتراضي
                </h2>
            </div>
            <div class="content" style="margin-top: 30px" dir="ltr">
                <vueper-slides
                    :touchable="false"
                    :bullets="false"
                    
                    ref="vueperslides2"
                    :gap="2"
                    slide-multiple
                    :breakpoints="{
                        700: { visibleSlides: 1, slideMultiple: 1 },
                        992: { visibleSlides: 2, slideMultiple: 2 },
                    }"
                    @slide="
                        $refs.vueperslides2 &&
                            $refs.vueperslides2.goToSlide(
                                $event.currentSlide.index,
                                { emit: false }
                            )
                    "
                    :visible-slides="4"
                    fixed-height="220px"
                    style="margin: 0px"
                >
                    <vueper-slide
                        v-for="(item, i) in content"
                        :key="i"
                        @click.native="
                            $refs.vueperslides2 &&
                                $refs.vueperslides2.goToSlide(i - 1)
                        "
                    >
                        <template #content>
                            <div class="participant_item"  data-aos="fade-up" data-aos-duration="1800">
                                <div class="participant-item_link">
                                    <div class="participant-item_bg"></div>

                                    <div class="participant-item_title">
                                        {{ item.title }}
                                    </div>
                                    <div class="participant-item_decs">
                                        {{ item.decs }}
                                    </div>
                                </div>
                            </div>
                        </template>
                    </vueper-slide>
                </vueper-slides>
            </div>
        </div>
    </section>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
export default {
    name: "Participant",
    components: {
        VueperSlides,
        VueperSlide,
    },
    data() {
        return {
            content: [
                {
                    title: 'توسـيع الوجـود الرقمـي',
                    decs: 'يتيـح للتجـار توسـيع وجودهـم الرقمـي والمشـاركة فـي العالـم الإلكترونـي.'
                },
                {
                    title: 'توفيـر تكاليـف التشـغيل',
                    decs: 'يمكـن للتجـار تقليـل التكاليـف التشـغيلية بشـكل كبيـر عـن طريـق اسـتئجار مسـاحات افتراضية بدلا من إنشاء متاجر في الواقع.'
                },
                {
                    title: 'تجربــة تســوق ممتعــة',
                    decs: 'يمكــن للمتاجــر تقديــم تجربــة تســوق مميــزة وممتعــة للعمــلاء باســتخدام تقنيــات الواقــع الافتراضي.'
                },
                {
                    title: 'اسـتهداف جمهـور مسـتهدف',
                    decs: 'يمكـن للتجـار اسـتهداف الجمهـور المسـتهدف بدقـة وإجـراء تحليـلات دقيقـة لســلوك المســتهلكين وتفضيلاتهــم.'
                },
                {
                    title: 'توســيع مجموعــة المنتجــات',
                    decs: 'يمكــن للتجــار تقديــم مجموعــة متنوعــة مــن المنتجــات والعــروض دون القيــود التــي يفرضها المساحات الجغرافية في المتاجر التقليدية.'
                },
                {
                    title: 'إمكانيـة التفاعـل مـع العمـلاء',
                    decs: 'يمكـن للتجـار التفاعـل مـع العمـلاء بشـكل مباشـر وتقديـم دعـم فـوري عبـر دردشـة حية أو جلسات واجهة افتراضية.'
                },
                {
                    title: 'الإعـلان والترويـج',
                    decs: 'يمكـن للتجـار اسـتخدام المنصـة للإعـلان عـن منتجاتهـم والترويـج لهـا بشـكل فعـال للعمـلاء المحتملين.'
                },
                {
                    title: 'توفيــر الوقــت والجهــد',
                    decs: 'يتيــح للتجــار العمــل فــي بيئــة افتراضيــة تتيــح لهــم تنظيــم المنتجــات وإدارة المخــزون بسهولة أكبر.'
                },
            ]
        };
    },
};

</script>


<style lang="scss">
.participant{
    padding: 50px 0px;
    margin: 90px 0px 50px;
}
.participant .row{
    row-gap: 50px;
}

.participant .section-title::before, .section-title::after  {
    content: "";
    position: absolute;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 0;
    height: 1px;
    width: 80px;
    background-color: var(--v-primary-base);
}
.participant .section-title{
  text-align: center;
}
.participant .section-title::after {
    right: 40%;
}
.participant .section-title::before {
    bottom: -5px;
    right: 45%;
}
@media (max-width: 767px){
    .participant .section-title{
        font-size: 25px;
        text-align: center;
    }
    .participant .section-title::after {
        right: 25%;
    }
    .participant .section-title::before {
        right: 35%;
    }
    // .participant .participant-item_bg{
    //   right: 280px !important
    // }
}

.participant_item {
  -ms-flex-preferred-size: calc(33.33333% - 30px);
  flex-basis: calc(33.33333% - 30px);

  overflow: hidden;

  border-radius: 28px;
  box-shadow: 0 0 10px rgba(128, 128, 128, 0.39);
}
.participant-item_link {
  display: block;
  padding: 35px 20px 20px;
  background-color: #fff;
  height: 200px;

  overflow: hidden;

  position: relative;
}
.participant-item_link:hover {
  text-decoration: none;
  color: #FFF;
}
.participant-item_link:hover .participant-item_decs{
  color: #FFF;
  transition: all .5s ease;

}
.participant-item_link:hover .participant-item_title{
  color: #FFF;
  transition: all .5s ease;

}
.participant-item_link:hover .participant-item_bg {
  -webkit-transform: scale(10);
  -ms-transform: scale(10);
  transform: scale(10);
}
.participant-item_title {
  margin-bottom: 20px;
  margin-top: 10px;

  overflow: hidden;

  font-weight: bold;
  font-size: 17px;
  color: var(--v-primary-base);
  text-align: right;
  z-index: 2;
  position: relative;
  transition: all .5s ease;

}
.participant-item_decs{

  overflow: hidden;

  font-size: 14px;
  color: var(--v-primary-base);
  text-align: right;
  z-index: 2;
  position: relative;
  transition: all .5s ease;

}
.participant-item_bg {
  height: 128px;
  width: 128px;
  background-color:  var(--v-secondary-base);

  z-index: 1;
  position: absolute;
  top: -84px;
  right: 78%;


  border-radius: 50%;

  -webkit-transition: all .8s ease;
  -o-transition: all .8s ease;
  transition: all .8s ease;
}



@media only screen and (max-width: 979px) {
  .participant_item {
    -ms-flex-preferred-size: calc(50% - 30px);
    flex-basis: calc(50% - 30px);
  }
  .participant-item_title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .ag-format-container {
    width: 96%;
  }

}
@media only screen and (max-width: 639px) {
  .participant_item {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
  .participant-item_title {
    min-height: 40px;
    line-height: 1;

    font-size: 18px;
  }
  .participant-item_link {
    padding: 22px 40px;
  }

}

// .participant .card {
//     // width: 300px;
//     height: 200px;
//     perspective: 1000px;
//     border: none !important;
//     margin: 10px 0px;
// }

// .participant .card-inner {
//     width: 100%;
//     height: 100%;
//     position: relative;
//     transform-style: preserve-3d;
//     transition: transform 0.999s;
// }

// .participant .card:hover .card-inner {
//     transform: rotateY(180deg);
// }

// .participant .card-front,
// .participant .card-back {
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     backface-visibility: hidden;
// }

// .participant .card-front {
//     background-color: var(--v-accent-base);
//     color: var(--v-secondary-base);
//     display: flex;
//     align-items: center;
//     border: 10px solid var(--v-accent-base);
//     border-radius: 10px;
//     justify-content: center;
//     font-size: 24px;
//     transform: rotateY(0deg);
//     text-align: center;
// }

// .participant .card-back {
//     background-color:  var(--v-secondary-base);
//     color: var(--v-accent-base);
//     display: flex;
//     align-items: center;
//     border: 10px solid  var(--v-secondary-base);
//     border-radius: 10px;
//     justify-content: center;
//     font-size: 18px;
//     transform: rotateY(180deg);
//     text-align: center;

// }

</style>