import { VImg } from 'vuetify/lib/components/VImg';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"contact",attrs:{"id":"contact"}},[_c('div',{staticClass:"custom-footer"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row",staticStyle:{"row-gap":"20px","text-align":"justify"}},[_c('div',{staticClass:"col-lg-5 footer-col"},[_c('router-link',{attrs:{"to":"/"}},[_c(VImg,{attrs:{"src":require("@/assets/img/logo.png"),"max-width":"100px","alt":"logo"}})],1),_c('p',{staticClass:"footer-desc"},[_vm._v(" مــول هديتــي الافتراضــي هــو مشــروع رائــد يهــدف إلــى تحويــل تجربـة التسـوق عبـر الإنترنـت إلـى تجربـة فريـدة ومثيـرة باسـتخدام تقنيــات الواقــع الافتراضــي. ")]),_c('ul',{staticClass:"social-links"},[_c('li',[_c('a',{staticClass:"social facebook",attrs:{"target":"_blank","href":"https://www.facebook.com"}},[_c('b-icon',{attrs:{"icon":"facebook"}})],1)]),_c('li',[_c('a',{staticClass:"social whatsapp",attrs:{"target":"_blank","href":"https://wa.me/00963999999999"}},[_c('b-icon',{attrs:{"icon":"whatsapp"}})],1)]),_c('li',[_c('a',{staticClass:"social linkedin",attrs:{"target":"_blank","href":"https://www.linkedin.com"}},[_c('b-icon',{attrs:{"icon":"linkedin"}})],1)])])],1),_c('div',{staticClass:"col-lg-1"}),_c('div',{staticClass:"col-lg-3 footer-col"},[_c('h5',{staticClass:"footer-title"},[_vm._v("اكتشف")]),_c('ul',{staticClass:"footer-ul"},_vm._l((_vm.title),function(item,index){return _c('li',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
                                el: '#' + item.scroll,
                                offset: -80,
                                force: true,
                            }),expression:"{\n                                el: '#' + item.scroll,\n                                offset: -80,\n                                force: true,\n                            }"}],key:index,class:'custom_' + item.scroll,staticStyle:{"margin-top":"18px"},on:{"click":function($event){return _vm.goToRoute(item.scroll)}}},[_vm._v(" "+_vm._s(item.text)+" ")])}),0)]),_c('div',{staticClass:"col-lg-3 footer-col"},[_c('h5',{staticClass:"footer-title"},[_vm._v("تواصل معنا")]),_c('ul',{staticClass:"footer-ul"},[_c('li',[_c('a',{staticClass:"row list-contact-item",attrs:{"target":"_blank","href":"https://www.google.com/maps"}},[_c('b-icon',{staticClass:"icon-contact",attrs:{"icon":"geo-alt-fill","aria-hidden":"true"}}),_c('p',[_vm._v("سوريا، دمشق")])],1)]),_c('li',[_c('a',{staticClass:"row list-contact-item",attrs:{"href":'mailto:info-h@hadyati.sy'}},[_c('b-icon',{staticClass:"icon-contact",attrs:{"icon":"envelope-fill","aria-hidden":"true"}}),_c('p',[_vm._v("info-h@hadyati.sy")])],1)]),_c('li',[_c('a',{staticClass:"row list-contact-item",attrs:{"href":'tel:+963989202098'}},[_c('b-icon',{staticClass:"icon-contact",attrs:{"icon":"telephone-fill","aria-hidden":"true"}}),_c('p',{staticStyle:{"direction":"ltr"}},[_vm._v("+963-989202098")])],1)])])])])])]),_c('copy')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }