<template>
    <div class="video-about video-hover">
        <div class="container">
            <div class="row text-center">
                <div class="col-lg-12">
                    <div class="mockup mockup-macbook loaded opened">
                        <div class="part top">
                            <img
                                src="@/assets/img/macbook-top.svg"
                                alt=""
                                class="img-fluid top"
                            />
                            <img
                                src="@/assets/img/macbook-cover2.svg"
                                alt=""
                                class="img-fluid cover"
                            />
                            <video autoplay="" muted="" controls="" loop="">
                                <source
                                    src="@/assets/img/video.mp4"
                                    type="video/mp4"
                                />
                            </video>
                        </div>
                        <div class="part bottom">
                            <img
                                src="@/assets/img/macbook-cover.svg"
                                alt=""
                                class="img-fluid cover"
                            />
                            <img
                                src="@/assets/img/macbook-bottom.svg"
                                alt=""
                                class="img-fluid bottom"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "VideoAbout",
    components: {
    },
    data() {
        return {
        };
    },
};

</script>


<style lang="scss">
.video-about{
    position: relative !important;
    padding: 0px 0px 100px;
}
.video-hover:hover .part.top {
    transform: translate3d(0, 0, 0) rotateX(0deg)
}

.mockup {
    display: inline-block;
    position: relative;
    z-index: 3;
    text-align: center;
    font-size: 0;
    perspective: 2400px;
    perspective-origin: 50% 100%;
    opacity: 0;
    transition: 500ms opacity
}

.mockup .part {
    display: inline-block;
    position: relative;
    transform-style: preserve-3d;
    transform-origin: 50% 100%;
    transition: 900ms
}

.mockup .part .top {
    position: absolute;
    top: 200px;
    left: 0;
    transform-origin: 50% 0;
    transform: translate3d(0, 0, -11px) rotateX(90deg);
    transition: 900ms
}

.mockup .part .bottom {
    position: absolute;
    top: 200px;
    left: 0;
    transform-origin: 50% 0;
    transform: translate3d(0, 0, 0) rotateX(90deg)
}

.mockup .part .cover {
    position: relative
}

.mockup .part.top {
    transform: translate3d(0, 0, 0) rotateX(-90deg)
}

.mockup img {
    display: block;
    max-width: 100%;
    backface-visibility: hidden
}

.mockup video {
    display: block;
    position: absolute;
    top: 28%;
    left: 4%;
    width: 92%;
    border-radius: 6px;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 1px)
}

.mockup .part.bottom {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate3d(0, 0, 0) rotateX(-90deg)
}

.mockup.loaded {
    opacity: 1
}

.mockup.loaded .part .top {
    transform: translate3d(0, 0, -11px) rotateX(90deg) scale(1, 1)
}

@media(max-width: 768px) {
    .mockup {
        perspective:7000px
    }
}

</style>