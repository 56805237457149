<template>
    <section class="about video-hover" id="about">
        <div class="container">
            <div class="row justify-content-center">
                <h2 class="section-title">حولنا</h2>
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <ul>
                            <li
                                data-aos="fade-left"
                                data-aos-duration="1200"
                                class="aos-init aos-animate"
                            >
                                <p class="text-muted">
                                    <b-icon icon="check-lg"></b-icon>
                                    مــول هديتــي الإفتراضــي هــو مشــروع
                                    رائــد يهــدف إلــى تحويــل تجربـة التسـوق
                                    عبـر الإنترنـت إلـى تجربـة فريـدة ومثيـرة
                                    باسـتخدام تقنيــات الواقــع الإفتراضــي.
                                </p>
                            </li>
                            <li
                                data-aos="fade-left"
                                data-aos-duration="1200"
                                class="aos-init aos-animate"
                            >
                                <p class="text-muted">
                                    <b-icon icon="check-lg"></b-icon>
                                    للمســتهلكين، مــول هديتــي الإفتراضــي
                                    يقــدم فرصــة لاستكشــاف المنتجـات والمتاجـر
                                    بطريقـة تشـبه التسـوق الحقيقـي، يسعى لتغييـر
                                    كيفيـة التسوق عبر الإنترنت وجعلها أكثر تفردا
                                    وواقعية.
                                </p>
                            </li>
                            <li
                                data-aos="fade-left"
                                data-aos-duration="1200"
                                class="aos-init aos-animate"
                            >
                                <p class="text-muted">
                                    <b-icon icon="check-lg"></b-icon>
                                    للعلامــات التجاريــة والبائعيــن، مول هديتي
                                    الإفتراضــي يقــدم منصــة إبداعيــة
                                    للترويــج لمنتجاتهــم وزيــادة مبيعاتهــم،
                                    مما يسهم فــي تحقيــق نجــاح متبــادل وتطور
                                    صناعة التسوق عبر الإنترنت نحو التفوق
                                    والاستدامة.
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-6">
                        <video-about
                            data-aos="fade-right"
                            data-aos-duration="1200"
                            class="aos-init aos-animate"
                        />
                    </div>
                    <!-- <div
                        class="col-lg-6"
                        data-aos="fade-right"
                        data-aos-duration="1200"
                        data-aos-delay="400"
                        style="
                            display: flex;
                            justify-content: center;
                            align-items: center;">
                        <img src="@/assets/img/logo.png" style="width: 200px" />
                    </div> -->
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import VideoAbout from './VideoAbout.vue';
export default {
    components: {
        VideoAbout
    },
    data() {
        return {
        };
    },
    methods: {

    },
    mounted() {
    },
}
</script>
<style>
.about{
    position: relative;
    z-index: 999;
    margin-bottom: 40px;
    padding-bottom: 40px;
}
.section-title{
    color: var(--v-primary-base);
    font-size: 40px;
    margin-bottom: 50px;
    position: relative;
    padding-bottom: 20px;
    line-height: 1.2;
}

.section-title::before, .section-title::after  {
    content: "";
    position: absolute;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 0;
    height: 1px;
    width: 80px;
    background-color: var(--v-primary-base);
}
.section-title::after {
    right: -60%;
}
.section-title::before {
    bottom: -5px;
    right: -30%;
}
.about p{
    text-align: justify;
    display: flex;
}
.about .bi-check-lg{
    color: var(--v-secondary-base);
    margin-left: 10px;
    margin-top: 5px
}
@media (max-width: 767px) {
    .section-title{
        font-size: 30px;
    }
    .section-title::after {
        right: -90%;
    }
    .section-title::before {
        right: -55%;
    }
}

</style>