<template>
    <div class="my-navbar" dir="rtl">
        <!-- <contact-bar /> -->
        <b-navbar :class="{ onScroll: !view.topOfPage }" toggleable="lg">
            <img
                v-if="screenWidth >= 992 && view.topOfPage"
                src="../assets/img/logo.png"
                alt="Hadyati Mall logo"
                class="logo"
            />
            <img
                v-if="!view.topOfPage || screenWidth < 992"
                src="../assets/img/logo.png"
                alt="Hadyati Mall logo"
                class="logo"
            />
            <b-navbar-toggle target="nav-collapse">
                <template #default="{ expanded }">
                    <b-icon v-if="expanded" icon="x-lg"></b-icon>
                    <b-icon v-else icon="list"></b-icon>
                </template>
            </b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav style="gap: 10px">
                    <b-nav-item
                        :class="'custom_' + item.scroll"
                        v-for="(item, index) in title"
                        :key="index"
                        v-scroll-to="{
                            el: '#' + item.scroll,
                            offset: -80,
                            force: true,
                        }"
                        @click="goToRoute(item.scroll)"
                    >
                        {{ item.text }}
                    </b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
import ContactBar from "./ContactBar.vue";
export default {
    name: "Navbar",
    components: {
        ContactBar,
    },
    data() {
        return {
            lang: "",
            scroll: "",
            section: "",
            title: [
                {
                    text: "الرئيسية",
                    scroll: "hero",
                },
                {
                    text: "حولنا",
                    scroll: "about",
                },
                {
                    text: "ميزاتنا",
                    scroll: "feature",
                },
                {
                    text: "الأقسام",
                    scroll: "category",
                },
                {
                    text: "للمستخدمين",
                    scroll: "customer",
                },
                {
                    text: "للمستثمرين",
                    scroll: "participant",
                },
                {
                    text: "استئجار مساحة",
                    scroll: "space",
                },
                {
                    text: "تواصل معنا",
                    scroll: "contact",
                },
            ],
            view: {
                topOfPage: true,
            },
            screenWidth: 0,
        };
    },

    methods: {
        handleScroll() {
            if (window.pageYOffset > 100) {
                if (this.view.topOfPage) {
                    this.view.topOfPage = false
                }
            } else {
                if (!this.view.topOfPage) {
                    this.view.topOfPage = true
                }
            }
        },
        onScreenResize() {
            window.addEventListener("resize", () => {
                this.updateScreenWidth()
            });
        },
        updateScreenWidth() {
            this.screenWidth = window.innerWidth
        },
        goToRoute(goal) {
            if (this.$route.name != "landing") {
                this.$router.replace({ name: "landing" })
            }
            this.scroll = goal;
            localStorage.setItem("scroll", this.scroll)
        },
        mySection() {
            const sections = document.querySelectorAll('section')
            const navLi = document.querySelectorAll('nav div ul li')
            // console.log(sections)
            window.addEventListener('scroll', () => {
                let current = ''
                sections.forEach(section => {
                    const sectionTop = section.offsetTop
                    const sectionHeight = section.clientHeight
                    if (pageYOffset >= (sectionTop - sectionHeight / 3)) {
                        current = section.getAttribute('id')
                    }
                })
                navLi.forEach((li, index) => {
                    if (index != 0) {
                        li.firstChild.classList.remove('active')
                        if (li.classList.contains("custom_" + current))
                            li.firstChild.classList.add('active')
                    }
                })
            })
        },

    },
    beforeMount() {
        window.addEventListener("scroll", this.handleScroll)
    },
    mounted() {
        this.updateScreenWidth()
        this.onScreenResize()
        this.mySection()
    },
};
</script>

<style lang="scss">
.navbar-collapse{
    justify-content: end !important;
}
.navbar-nav {
    direction: rtl;
}

@media (max-width: 991px) {
    .navbar-nav {
        padding-right: 0px;
    }
}

.navbar.onScroll {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 999999;
    transition: all 0.3s;
}

.my-navbar {
    padding: 0px;
    z-index: 1000000000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.my-navbar .navbar-light .navbar-nav a.nav-link {
    color: #fff;
    font-size: 16px;
    // font-weight: 800;
    border-bottom: 1px solid transparent;
    // transition: all 0.3s ease-in;
    letter-spacing: 1.5px;

    &.onScroll {
        color: var(--v-primary-base);
    }
}

.my-navbar .navbar-light .navbar-nav .nav-link:focus {
    color: #fff !important;
}

.my-navbar .navbar-light.onScroll .navbar-nav a.nav-link {
    color: var(--v-primary-base) !important;
    // font-weight: 800;
    // transition: all 0.3s ease-in;
}

.my-navbar .navbar-light .navbar-nav a.nav-link:hover {
    color: #fff;
    border-bottom-color: #fff !important;
    transition: all 0.3s ease-in;
}

.active {
    border-bottom-color: var(--v-primary-base) !important;
    transition: all 0.3s ease-in;
}

.my-navbar .navbar-light .navbar-nav .section a.nav-link {
    color: #fff;
    border-bottom-color: #fff !important;
    transition: all 0.3s ease-in;
}

.my-navbar .navbar-light.onScroll .navbar-nav a.nav-link:hover {
    border-bottom-color: var(--v-primary-base) !important;
    transition: all 0.3s ease-in;
}

.my-navbar .navbar-light.onScroll .navbar-nav .section a.nav-link {
    border-bottom-color: var(--v-primary-base) !important;
    transition: all 0.3s ease-in;
}

.my-navbar .navbar-light {
    margin: 0px !important;
    padding: 15px 50px !important;
    // transition: all .5s ease-in;

    &.onScroll {
        box-shadow: 0 0 10px #aaa;
        background-color: #fffffff7;
    }
}

@media (max-width: 991px) {
    .my-navbar .navbar {
        height: auto !important;
    }

    .my-navbar .navbar-light {
        background-color: #fffffff7;
        box-shadow: 0 0 10px #aaa;
    }

    .my-navbar .navbar-light .navbar-nav a.nav-link {
        color: var(--v-primary-base) !important;
        // font-weight: 800 !important;
    }

    .my-navbar .navbar-light .navbar-nav a.nav-link:hover {
        border-bottom-color: var(--v-primary-base) !important;
        transition: all 0.3s ease-in;
    }

    .my-navbar .btn {
        color: var(--v-primary-base) !important;
        transition: all 0.3s ease-in;
    }
}

.my-navbar .btn {
    width: 80px !important;
    background-color: transparent !important;
    border: none !important;
    border-bottom: 1px solid transparent !important;
    border-radius: 0px !important;
    // font-weight: 800 !important;
    transition: all 0.3s ease-in;
}

.my-navbar .onScroll .btn {
    color: var(--v-primary-base) !important;
    transition: all 0.3s ease-in;
}

.my-navbar .btn-secondary:focus,
.my-navbar .btn-secondary.focus {
    box-shadow: none !important;
}

.my-navbar .navbar-nav {
    margin-left: 20px;
    align-items: center;
}

.my-navbar .navbar-expand-lg {
    margin-right: 0.5vw;
}

.my-navbar .dropdown-toggle::after {
    display: none !important;
}

.my-navbar .dropdown .dropdown-menu {
    transition: all 0.3s ease-in;
    max-height: 0;
    display: block;
    opacity: 0;
}

.my-navbar .dropdown.show .dropdown-menu {
    max-height: 200px;
    opacity: 1;
}

.my-navbar .logo {
    margin-left: 40px;
    width: 60px;
}

@media (max-width: 991px) {
    .my-navbar .hidden {
        display: none !important;
    }

    .my-navbar .logo {
        margin-left: 0px !important;
    }
}

.my-navbar .navbar .collapsed,
.my-navbar .navbar .not-collapsed {
    border: none;
    color: var(--v-primary-base) !important;
    font-size: 25px;
}

.my-navbar .navbar .not-collapsed {
    font-size: 25px;
    margin-top: 10px;
    margin-right: 5px;
}

.my-navbar .navbar .bi-list {
    font-size: 40px;
}



.my-navbar .bi-chevron-down {
    font-size: 15px !important;
}

.my-navbar .dropdown-item.active,
.my-navbar .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: var(--v-primary-base) !important;
}

.my-navbar .dropdown {
    top: -10px;
    right: 40px;
}
</style>
