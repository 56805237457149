<template>
    <div id="contact-bar" class="contact-bar">
        <div class="row" v-if="screenWidth < 992" style="flex-wrap: nowrap;width: 100%;">
            <div class="col-lg-6">
            </div>
            <div class="col-lg-6" style="padding: 0px;">
                <a class="icons " href=""  target="_blank">
                    <div class="row" style="gap: 10px;margin: 0px;justify-content: end;font-size: 15px;">
                        <p>+963 999999999</p>
                        <b-icon icon="whatsapp"></b-icon>
                    </div>
                </a>
            </div>
        </div>
        <div class="row" style="justify-content: space-between" v-else>
            <div class="left-side row" style="gap: 70px;">
                <div class="row" style="gap: 20px;">
                    <div class="social-icon">
                        <a href="" target="_blank" class="icons">
                            <b-icon icon="facebook"></b-icon>
                        </a>
                    </div>
                    <div class="social-icon">
                        <a href="" target="_blank" class="icons">
                            <b-icon icon="instagram"></b-icon>
                        </a>
                    </div>
                    <div class="social-icon">
                        <a href="" target="_blank" class="icons">
                            <b-icon icon="linkedin"></b-icon>
                        </a>
                    </div>
                </div>
            </div>
            <div class="right-side row" v-if="screenWidth >= 992">
                <a class="row icons" style="gap: 10px;" href="" target="_blank">
                    <p>+963 999999999</p>
                    <b-icon icon="whatsapp"></b-icon>
                </a>
                | <a class="row icons" style="gap: 10px;" href="">
                    <p>+963 999999999</p>
                    <b-icon class="icon-contact" icon="telephone-fill" aria-hidden="true"></b-icon>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ContcatBar",
    data() {
        return {
            screenWidth: 0,
        };
    },
    computed: {
    },
    methods: {
        onScreenResize() {
            window.addEventListener("resize", () => {
                this.updateScreenWidth();
            });
        },
        updateScreenWidth() {
            this.screenWidth = window.innerWidth;
        },
        
    },
    mounted() {
        this.updateScreenWidth();
        this.onScreenResize();
    },
};

</script>


<style lang="scss">
.contact-bar {
    color: #fff;
    padding: 20px 95px 0px 135px;
    border-bottom: 0.1px solid #ffffff7a;
    z-index: 10000;
    position: relative;
}

@media (max-width: 991px) {
    .contact-bar {
        background: rgba(255, 255, 255, 0.968627451);
        padding: 15px 0px 0px 45px;
    }

    .contact-bar .icons {
        color: var(--main-blue) !important;
        margin-right: 0px;
    }

    .my-navbar .navbar .collapsed {
        padding: 0px;
    }
}

.contact-bar .right-side {
    gap: 50px;
    direction: ltr;
}

.contact-bar .bi-globe {
    font-size: 17px !important;
}

.contact-bar .b-icon {
    margin-top: 5px;
    cursor: pointer;
    margin-bottom: 2px;
}

.contact-bar .social-icon:hover {
    cursor: pointer;
    font-size: 19px;
    transition: all .3s;
}

.contact-bar .social-icon {
    transition: all .3s;
}

.contact-bar .icons {
    color: #fff;
}

.contact-bar .bi-chevron-down {
    font-size: 15px !important;
}

.contact-bar .dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: var(--main-blue) !important;
}

.contact-bar .dropdown {
    top: -10px;
    right: 40px;
}

@media (max-width: 991px) {
    .contact-bar .dropdown-toggle {
        margin-left: 25px;
    }
}
</style>
