<template>
    <section class="space" id="space">
        <div class="container">
            <div class="row justify-content-center">
                <h2 class="section-title">استئجار مساحة</h2>
            </div>
            <div class="row align-items-center">
                <div
                    class="col-lg-6"
                    style="text-align: justify"
                    data-aos="fade-left"
                    data-aos-duration="1800"
                    data-aos-delay="400"
                >
                    <v-form>
                        <div class="row">
                            <div class="col-lg-6">
                                <v-text-field
                                    outlined
                                    :reverse="true"
                                    label="الاسم الكامل"
                                    v-model="full_name"
                                    :error-messages="nameErrors"
                                ></v-text-field>
                                <v-text-field
                                    outlined
                                    :reverse="true"
                                    v-model="vendor_name"
                                    :error-messages="vendor_nameErrors"
                                    label="اسم المتجر"
                                ></v-text-field>
                            </div>
                            <div class="col-lg-6">
                                <v-text-field
                                    outlined
                                    :reverse="true"
                                    v-model="phone"
                                    :error-messages="phoneErrors"
                                    label="رقم الموبايل"
                                ></v-text-field>
                                <v-select
                                    outlined
                                    :reverse="true"
                                    :items="category_list"
                                    v-model="category_id"
                                    :error-messages="categoryErrors"
                                    item-text="name"
                                    item-value="id"
                                    label="التصنيف"
                                ></v-select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <v-textarea
                                    outlined
                                    :reverse="true"
                                    v-model="notes"
                                    :error-messages="notesErrors"
                                    label="ملاحظات"
                                ></v-textarea>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-12">
                                <vue-recaptcha
                                    ref="recaptcha"
                                    sitekey="6LeRM6ooAAAAAMlUH4RzG-jJlLooglnsK5aOgor-"
                                    style="margin-top: 10px;"
                                    @verify="verify"
                                    @expired="expired"
                                    :load-recaptcha-script="true"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <v-btn
                                    @click="submit"
                                    :disabled="isSubmit && !response"
                                    color="primary"
                                    light
                                    style="margin-top: 35px; padding: 20px"
                                    rounded
                                >
                                    ارسال
                                    <v-progress-circular
                                        :size="20"
                                        v-if="isSubmit && !response"
                                        indeterminate
                                        color="white"
                                        style="margin: 0px 8px"
                                    ></v-progress-circular>
                                </v-btn>
                            </div>
                        </div>
                    </v-form>
                </div>
                <div
                    class="col-lg-6"
                    data-aos="fade-right"
                    data-aos-duration="1800"
                    data-aos-delay="400"
                >
                    <div class="position-relative featured-mockup">
                        <div class="bordered-box"></div>
                        <img
                            class="space-image"
                            src="@/assets/img/form2.webp"
                            alt=""
                        />
                    </div>
                </div>
                <v-snackbar
                    right
                    top
                    color="green"
                    text
                    v-model="snackbar"
                    :timeout="3000"
                >
                    تم ارسال طلبك بنجاح، سيتم التواصل معك قريبا
                    <template v-slot:action="{ attrs }">
                        <v-btn
                            color="green"
                            text
                            v-bind="attrs"
                            @click="snackbar = false"
                        >
                            اغلاق
                        </v-btn>
                    </template>
                </v-snackbar>
                <v-snackbar
                    right
                    top
                    color="red"
                    text
                    v-model="error_snackbar"
                    :timeout="3000"
                >
                    حدث خطأ غير متوقع، الرجاء إعادة المحاولة
                    <template v-slot:action="{ attrs }">
                        <v-btn
                            color="red"
                            text
                            v-bind="attrs"
                            @click="error_snackbar = false"
                        >
                            اغلاق
                        </v-btn>
                    </template>
                </v-snackbar>
            </div>
        </div>
    </section>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, numeric, minLength, maxLength } from 'vuelidate/lib/validators'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import VueRecaptcha from "vue-recaptcha"


export default {
    name: "Space",
    components: { VueRecaptcha },
    mixins: [validationMixin],

    data() {
        return {
            // category: [
            //     "الملابس والاحذية والاكسسوارات النسائية",
            //     "الملابس والاحذية والاكسسوارات الرجالية",
            //     "الملابس والاحذية والاكسسوارات الولادية",
            //     "السوبر ماركت والبقالة",
            //     "الاكترونيات والاجهزة الكهربائية",
            //     "متاجر بيع الهدايا والتذكارات",
            //     "متاجر منوعة - اسر منتجة ومنتجات يدوية",
            //     "المطاعم والوجبات السريعة",
            // ],
            full_name: '',
            phone: '',
            vendor_name: '',
            category_id: '',
            category_list: [],
            notes: '',
            response: false,
            isSubmit: false,
            snackbar: false,
            error_snackbar: false,
            my_verify: false,
        };
    },
    validations: {
        full_name: { required },
        category_id: { required },
        phone: { required, numeric, minLength: minLength(10), maxLength: maxLength(10) },
        vendor_name: { required },
        notes: { required },
    },
    computed: {
        nameErrors() {
            const errors = []
            if (!this.$v.full_name.$dirty) return errors
            !this.$v.full_name.required && errors.push('هذا الحقل مطلوب')
            return errors
        },
        phoneErrors() {
            const errors = []
            if (!this.$v.phone.$dirty) return errors
            !this.$v.phone.required && errors.push('هذا الحقل مطلوب')
            !this.$v.phone.numeric && errors.push('الرجاء ادخال رقم موبايل صحيح')
            !this.$v.phone.minLength && errors.push('الرجاء ادخال رقم موبايل مكون من 10 ارقام')
            !this.$v.phone.maxLength && errors.push('الرجاء ادخال رقم موبايل مكون من 10 ارقام')
            return errors
        },
        vendor_nameErrors() {
            const errors = []
            if (!this.$v.vendor_name.$dirty) return errors
            !this.$v.vendor_name.required && errors.push('هذا الحقل مطلوب')
            return errors
        },
        categoryErrors() {
            const errors = []
            if (!this.$v.category_id.$dirty) return errors
            !this.$v.category_id.required && errors.push('هذا الحقل مطلوب')
            return errors
        },
        notesErrors() {
            const errors = []
            if (!this.$v.notes.$dirty) return errors
            !this.$v.notes.required && errors.push('هذا الحقل مطلوب')
            return errors
        }
    },
    methods: {
        verify() {
            this.my_verify = true
        },
        expired() {
            this.my_verify = false
            this.$refs.recaptcha.reset();
        },
        submit() {
            console.log(this.my_verify)
            this.$v.$touch()
            if (!this.$v.$error && this.my_verify) {
                this.isSubmit = true
                this.sendData()
            }
        },
        sendData() {
            const formData = new FormData()
            formData.append('full_name', this.full_name)
            formData.append('notes', this.notes)
            formData.append('vendor_name', this.vendor_name)
            formData.append('phone', this.phone)
            formData.append('vendor_category_id', this.category_id)
            for (var pair of formData.entries()) {
                console.log(pair)
            }
            this.addData(formData)
        },
        addData(formData) {
            this.axios.post("https://hadyati.mall.peaklink.site/public/api/space-reservestions", formData)
                .then(res => {
                    console.log(res.data)
                    this.response = true
                    if (res.data.status == "Success") {
                        this.snackbar = true
                    }
                })
                .catch(error => {
                    console.log(error)
                    this.error_snackbar = true
                    this.isSubmit = false
                    this.response = false
                });
        },
        get_category() {
            this.axios.get("https://hadyati.mall.peaklink.site/public/api/vendor_categories")
                .then(res => {
                    // console.log(res.data)
                    this.category_list = res.data.data
                })
        }
    },
    mounted() {
        this.get_category()
    }
};

</script>


<style lang="scss">

.v-snack{
    justify-content: flex-start !important;
    z-index: 999999 !important;
    top: 100px !important;
}
.vue-friendly-captcha {
    padding: 18px !important;;
    border: 1px solid gray !important;;
    border-radius: 20px !important;;
    width: -webkit-fill-available !important;
}
.v-input__slot{
    border-radius: 15px !important;
}
.v-textarea label{
    padding: 0px 10px;
    right: 13px !important;
}
.v-select label{
    padding: 0 30px;
}
.v-list{
    text-align: justify;
}
.v-messages{
    text-align: right !important;
}
.v-input{
    margin-top: 10px !important
}
.space{
    padding: 50px 0px;
    margin: 50px 0px;
}
.space .section-title::before, .section-title::after  {
    content: "";
    position: absolute;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 0;
    height: 1px;
    width: 80px;
    background-color: var(--v-primary-base);
}
.space .section-title::after {
    right: 10%;
}
.space .section-title::before {
    bottom: -5px;
    right: 25%;
}
@media (max-width: 767px){
    .space .section-title::after {
        right: 0%;
    }
    .space .section-title::before {
        right: 15%;
    }
}
@media (max-width: 992px){
    .featured-mockup{
        display: none;
    }
}
.featured-mockup{
    min-height: 450px;
}
.bordered-box{
    background: linear-gradient(to right bottom, #0C366A 40%, #184e91 70%);
    background-color: #0C366A;
    animation: roatateAnim 15s linear infinite;
    height: 70%;
    border-radius: 50px;
    position: absolute;
    left: 65px;
    width: 70%;
    top: 75px;
}




@keyframes roatateAnim{
	0%{
        opacity: 1;
        transform: translateY(10px);
        transform: rotate(10deg);
	}
	100%{
        opacity: 2;
        transform: translate(0px);
        transform: rotate(360deg);
	}
}

.space-image{
    position: absolute;
    left: 0;
    z-index: 1;
    animation-name: MoveUpDown;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

@keyframes MoveUpDown {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(30px);
}
}
</style>