import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    url: "https://tourism.peaklink.site",
    token: null,
    user: null,
    public_service: [],
    restaurant_feature: [],
    occasions_feature: [],
    tour_guids: [],
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
