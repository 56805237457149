<template>
    <section class="customer" id="customer">
        <div class="bg-shap">
            <img
                src="@/assets/img/5.png"
                alt="astriol shape bg"
                style="visibility: visible"
            />
        </div>
        <div class="container">
            <div class="row justify-content-center">
                <h2 class="section-title">للمستخدمين</h2>
            </div>
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <ul class="inline-item">
                        <li
                            class="list-inline-item"
                            data-aos="fade-left"
                            data-aos-duration="1800"
                            data-aos-delay="200"
                        >
                            <b-icon icon="headset-vr"></b-icon>
                            <p class="text-muted">
                                يمكـن للـزوار تجربـة الملابـس افتراضيـا
                                باسـتخدام تقنيـة تغييـر الألبسـة، حيـث يمكنهم
                                اختيار الملابس واستعراض كيف ستبدو عليهم.
                            </p>
                        </li>
                        <li
                            class="list-inline-item"
                            data-aos="fade-left"
                            data-aos-duration="1800"
                            data-aos-delay="300"
                        >
                            <b-icon icon="headset-vr"></b-icon>
                            <p class="text-muted">
                                يضـم المـول مجموعـة متنوعـة من العلامـات
                                التجاريـة والمنتجات، مما يوفـر خيارات واسعة
                                للمستهلكين.
                            </p>
                        </li>
                        <li
                            class="list-inline-item"
                            data-aos="fade-left"
                            data-aos-duration="1800"
                            data-aos-delay="400"
                        >
                            <b-icon icon="headset-vr"></b-icon>
                            <p class="text-muted">
                                السماح للمسـتهلكين بالتفاعـل مـع بعضهـم البعـض
                                أثنـاء التسـوق، ممـا يعكـس تجربة التسوق
                                الاجتماعية التقليدية.
                            </p>
                        </li>
                        <li
                            class="list-inline-item"
                            data-aos="fade-left"
                            data-aos-duration="1800"
                            data-aos-delay="500"
                        >
                            <b-icon icon="headset-vr"></b-icon>
                            <p class="text-muted">
                                يمكــن للمســتخدمين تخصيــص تجربتهــم التســوقية
                                واختيــار المنتجــات والمتاجــر التــي يفضلونها.
                            </p>
                        </li>
                        <li
                            class="list-inline-item"
                            data-aos="fade-left"
                            data-aos-duration="1800"
                            data-aos-delay="600"
                        >
                            <b-icon icon="headset-vr"></b-icon>
                            <p class="text-muted">
                                جعـل التسـوق عبـر الإنترنـت تجربـة ممتعـة
                                وتفاعليـة تجعـل المستخدمين يشعرون بأنهم يتسوقون
                                في الواقع.
                            </p>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-6">
                    <div
                        class="section-content"
                        data-aos="fade-right"
                        data-aos-duration="1800"
                        data-aos-delay="400"
                    >
                        <div class="customer-contaent">
                            <div class="customer-right">
                                <div class="customer-circle">
                                    <div class="media">
                                        <img
                                            src="@/assets/img/customer.webp"
                                            style="
                                                height: 103%;
                                                margin-right: 40px;
                                            "
                                            alt="icon"
                                        />
                                    </div>
                                    <span class="ring1 animate-v2">
                                        <img
                                            src="@/assets/img/glasses.png"
                                            class="img-fluid"
                                            alt="icon"
                                        />
                                    </span>
                                    <span class="ring2 animate-v3">
                                        <img
                                            src="@/assets/img/icon4.png"
                                            class="img-fluid"
                                            alt="icon"
                                        />
                                    </span>
                                    <span class="ring3 animate-v2">
                                        <img
                                            src="@/assets/img/icon9.png"
                                            class="img-fluid"
                                            alt="icon"
                                        />
                                    </span>
                                    <span class="ring4 animate-v3">
                                        <img
                                            src="@/assets/img/icon11.png"
                                            class="img-fluid"
                                            alt="icon"
                                        />
                                    </span>
                                    <span class="ring5 animate-v2">
                                        <img
                                            src="@/assets/img/icon10.png"
                                            class="img-fluid"
                                            alt="icon"
                                        />
                                    </span>
                                    <span class="ring6 animate-v3"></span>
                                    <span class="ring7 animate-v2"></span>
                                    <span class="ring8 animate-v3"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    name: "Customer",
    components: {
    },
    data() {
        return {
        };
    },
};

</script>


<style lang="scss">
.customer{
    margin: 40px 0px;
    padding: 40px 0px;
}
.customer .bg-shap{
    position: absolute;
    top: 147%;
    left: -18%;
    z-index: 0;
}
.customer .section-title::before, .section-title::after  {
    content: "";
    position: absolute;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 0;
    height: 1px;
    width: 80px;
    background-color: var(--v-primary-base);
}
.customer .section-title::after {
    right: 0%;
}
.customer .section-title::before {
    bottom: -5px;
    right: 18%;
}

@media (max-width: 767px){
    .customer .section-title{
        font-size: 30px;
    }
    .customer .section-title::after {
        right: -10%;
    }
    .customer .section-title::before {
        right: 10%;
    }
}

.customer .list-inline-item {
    display: flex;
    align-items: center;
    column-gap: 15px;
    text-align: justify;
    margin:  0.5rem;
}


.customer .bi-headset-vr{
    color: var(--v-primary-base);
    font-size: 30px;
    transition: all .3s;
}
.customer .list-inline-item:hover .bi-headset-vr{
    color: var(--v-secondary-base);
    transition: all .3s;
}
.customer-contaent {
    position: relative;
    padding: 45% 0px
}

.customer-right {
    position: absolute;
    right: 90px;
    top: 90px;
}
.customer-circle {
    position: relative;
}
.customer-circle .media {
    width: 297px;
    height: 297px;
    position: relative;
}

.customer-circle .media:before {
    content: "";
    position: absolute;
    border: 1px dashed #838485;
    border-radius: 50%;
    width: 126%;
    height: 126%;
    left: -13%;
    top: -13%
}

.customer-circle .media:after {
    content: "";
    position: absolute;
    border: 1px dashed #838485;
    border-radius: 50%;
    width: 160%;
    height: 160%;
    left: -30%;
    top: -30%
}

.customer-circle .media img {
    width: auto
}

.customer-circle span {
    border-radius: 50%;
    background: #fff;
    display: inline-block
}

.customer-circle span img {
    border-radius: 50%
}

.customer-circle span.ring1 {
    position: absolute;
    bottom: 30px;
    left: -100px;
    box-shadow: 2px 10px 10px rgba(0,0,0,.2)
}

.customer-circle span.ring1 img {
    padding: 3px;
    width: 52px;
    height: 52px;
    background-color: #fff
}

.customer-circle span.ring2 {
    position: absolute;
    top: 10px;
    left: -30px;
    box-shadow: 2px 10px 10px rgba(0,0,0,.2)
}

.customer-circle span.ring2 img {
    padding: 3px;
    width: 80px;
    height: 80px;
    width: 54px;
    height: 54px;
    background-color: #fff
}

.customer-circle span.ring3 {
    position: absolute;
    right: -100px;
    bottom: 0px;
    box-shadow: 2px 10px 10px rgba(0,0,0,.2)
}

.customer-circle span.ring3 img {
    padding: 3px;
    width: 80px;
    height: 80px;
    width: 76px;
    height: 76px;
    background-color: #fff
}

.customer-circle span.ring4 {
    position: absolute;
    bottom: -70px;
    left: 50%;
    margin-left: -33px;
    box-shadow: 2px 10px 10px rgba(0,0,0,.2)
}

.customer-circle span.ring4 img {
    padding: 3px;
    width: 80px;
    height: 80px;
    width: 66px;
    height: 66px;
    background-color: #fff
}

.customer-circle span.ring5 {
    position: absolute;
    top: -90px;
    right: -30px;
    box-shadow: 2px 10px 10px rgba(0,0,0,.2)
}

.customer-circle span.ring5 img {
    padding: 3px;
    width: 80px;
    height: 80px;
    width: 86px;
    height: 86px;
    background-color: #fff
}

.customer-circle span.ring6 {
    position: absolute;
    width: 32px;
    height: 32px;
    background-color: var(--v-secondary-base);
    right: 10px;
    bottom: -70px
}

.customer-circle span.ring7 {
    position: absolute;
    width: 22px;
    height: 22px;
    background-color: var(--v-secondary-base);
    top: 50%;
    left: -50px
}

.customer-circle span.ring8 {
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: var(--v-secondary-base);
    right: -44px;
    top: 40%
}

.animate-v {
    animation: upNdown1 2s infinite linear
}

.animate-curve:after {
    animation: upNdown2 2s infinite linear;
    animation: upNdown2 2s infinite linear
}

.animate-v2 {
    animation: upNdown11 2s infinite linear
}

.animate-v3 {
    animation: upNdown12 2s infinite linear
}


@keyframes upNdown1 {
    50% {
        transform: translateY(30px)
    }
}

@keyframes upNdown2 {
    50% {
        transform: translateY(10px)
    }
}

@keyframes upNdown2 {
    50% {
        transform: translateY(10px)
    }
}

@keyframes upNdown11 {
    50% {
        transform: translateY(5px)
    }
}

@keyframes upNdown12 {
    50% {
        transform: translateY(-5px)
    }
}


@media only screen and (max-width: 991px) {
    .aon-med-customer2-area {
        padding:30px 0px
    }

    .customer-circle {
        display: none
    }

    .customer-circle .media {
        margin-top: 50px;
        width: 120px;
        height: 120px;
        position: relative;
        transition: all .5s ease;
        display: none
    }

    .customer-circle span.ring1 {
        bottom: 5px;
        left: -48px
    }

    .customer-circle span.ring1 img {
        width: 32px;
        height: 32px
    }

    .customer-circle span.ring2 {
        top: 10px;
        left: -30px
    }

    .customer-circle span.ring2 img {
        width: 34px;
        height: 34px
    }

    .customer-circle span.ring3 {
        right: -60px;
        bottom: 0px
    }

    .customer-circle span.ring3 img {
        width: 56px;
        height: 56px
    }

    .customer-circle span.ring4 {
        bottom: -65px;
        left: 50%;
        margin-left: -33px
    }

    .customer-circle span.ring4 img {
        width: 46px;
        height: 46px
    }

    .customer-circle span.ring5 {
        top: -60px;
        right: -30px
    }

    .customer-circle span.ring5 img {
        width: 66px;
        height: 66px
    }

    .customer-circle span.ring6 {
        width: 16px;
        height: 16px;
        right: 10px;
        bottom: -70px
    }

    .customer-circle span.ring7 {
        width: 8px;
        height: 8px;
        top: 50%;
        left: -50px
    }

    .customer-circle span.ring8 {
        width: 4px;
        height: 4px;
        right: -44px;
        top: 40%
    }

    .aon-med-customer2-left {
        max-width: 100%
    }

    .customer-contaent {
        position: relative;
        padding: 0px 0px;
        margin-bottom: 10px
    }
}

@media only screen and (max-width: 1199px) {
    .aon-med-customer2-left {
        max-width:540px
    }
}

@media only screen and (max-width: 480px) {
    .aon-med-customer-2 {
        padding:0px
    }

    .aon-med-customer.aon-owl-arrow.left .owl-nav {
        padding-left: 0px
    }
}

@media only screen and (max-width: 1200px) {
    .customer-circle .media {
        width:200px;
        height: 200px;
        position: relative;
        transition: all .5s ease
    }

    .customer-circle span.ring1 {
        bottom: 30px;
        left: -70px
    }

    .customer-circle span.ring1 img {
        width: 42px;
        height: 42px
    }

    .customer-circle span.ring2 {
        top: 10px;
        left: -30px
    }

    .customer-circle span.ring2 img {
        width: 44px;
        height: 44px
    }

    .customer-circle span.ring3 {
        right: -80px;
        bottom: 0px
    }

    .customer-circle span.ring3 img {
        width: 66px;
        height: 66px
    }

    .customer-circle span.ring4 {
        bottom: -85px;
        left: 50%;
        margin-left: -33px
    }

    .customer-circle span.ring4 img {
        width: 56px;
        height: 56px
    }

    .customer-circle span.ring5 {
        top: -90px;
        right: -30px
    }

    .customer-circle span.ring5 img {
        width: 76px;
        height: 76px
    }

    .customer-circle span.ring6 {
        width: 22px;
        height: 22px;
        right: 10px;
        bottom: -70px
    }

    .customer-circle span.ring7 {
        width: 12px;
        height: 12px;
        top: 50%;
        left: -50px
    }

    .customer-circle span.ring8 {
        width: 6px;
        height: 6px;
        right: -44px;
        top: 40%
    }
}






@media (max-width: 991px) {

    .customer .bg-shap{
        display: none !important;
    }
    .customer{
        background-color: #f2f2f88c;
    }
}
</style>