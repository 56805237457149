var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"category",attrs:{"id":"category"}},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"content",staticStyle:{"margin-top":"30px"},attrs:{"dir":"ltr"}},[_c('vueper-slides',{ref:"vueperslides1",staticStyle:{"margin":"0px"},attrs:{"touchable":false,"bullets":false,"autoplay":"","gap":2,"slide-multiple":"","breakpoints":{
                    700: { visibleSlides: 1, slideMultiple: 1 },
                    992: { visibleSlides: 2, slideMultiple: 2 },
                },"visible-slides":5,"fixed-height":"240px"},on:{"slide":function($event){_vm.$refs.vueperslides1 &&
                        _vm.$refs.vueperslides1.goToSlide(
                            $event.currentSlide.index,
                            { emit: false }
                        )}}},_vm._l((_vm.data),function(item,i){return _c('vueper-slide',{key:i,nativeOn:{"click":function($event){_vm.$refs.vueperslides1 &&
                            _vm.$refs.vueperslides1.goToSlide(i - 1)}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('category-card',{attrs:{"title":item.title,"image":item.image,"data-aos":"zoom-out-up","data-aos-delay":"500","data-aos-duration":"1800"}})]},proxy:true}],null,true)})}),1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center"},[_c('h2',{staticClass:"section-title"},[_vm._v("أقسام مول هديتي الإفتراضي")])])
}]

export { render, staticRenderFns }