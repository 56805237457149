<template>
  <v-app class="ar">
    <div>
      <v-main>
        <router-view />
      </v-main>
    </div>

  </v-app>
</template>

<script>

export default {
  name: 'App',
  components: {

  },
  data: () => ({
    lang: '',
  }),
  computed: {
    
  },
  created() {

  },
};
</script>

<style lang="scss">
 * {
  letter-spacing: 0 !important;
}
ul {
    list-style-type: none !important;
}


@font-face {
  font-family: "Almarai";
  src: local("Almarai"),
    url(assets/font/Almarai-Regular.ttf) format("truetype");
}



.ar {
  font-family: 'Almarai', sans-serif !important;
  direction: rtl;
}
</style>
