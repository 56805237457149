<template>
    <div class="copy row" dir="ltr">
        Copyright ©2023. All Rights Reserved By <a href="https://site.peaklinkdemo.com/" class="peak" target="_blank"> Peak Link</a>
    </div>
</template>

<script>

export default {
    name: "Copy",
    components: {
    },
    data() {
        return {
        };
    },
};

</script>


<style lang="scss">
.copy{
    justify-content: center;
    padding:  20px;
    background: var(--v-primary-base);
    color: #fff;
}
.peak{
    color: var(--v-accent-base) !important;
    font-size: 17px;
    margin-left: 5px;
}
.peak:hover{
    color: var(--v-secondary-base) !important;
}
.row{
    margin-right: 0px !important;
    margin-left: 0px !important
}

</style>