import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
// import  { vApp, VMain, VBtn, VCard, VImg, VForm, VTextField, VSelect, VTextarea } from 'vuetify'
// Vue.use(vApp, VMain, VBtn, VCard, VImg, VForm, VTextField, VSelect, VTextarea)



// Import Bootstrap and BootstrapVue CSS files 
import 'bootstrap/dist/css/bootstrap.min.css'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)


import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
Vue.use(VueperSlides, VueperSlide)


import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo)


import AOS from 'aos'
import 'aos/dist/aos.css'

import i18n from './i18n'


import VueRecaptcha from "vue-recaptcha"
Vue.use(VueRecaptcha)


import ScrollAnimation from "./directives/scrollanimation"
Vue.directive('scrollanimation', ScrollAnimation)


import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCPsxZeXKcSYK1XXw0O0RbrZiI_Ekou5DY',
    libraries: 'places',
  },
  installComponents: true
})

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  store,
  vuetify,
  render: function (h) { return h(App) },
  mounted() {
    AOS.init()
  },
}).$mount('#app')
