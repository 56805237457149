
<template>
    <div class="landing overflow-hidden-x">
        <navbar />
        <hero />
        <about />
        <feature />
        <category />
        <customer />
        <participant />
        <space-form />
        <custom-footer />
    </div>
    <!-- <loadar-2 v-else /> -->
</template>

<script>
import Navbar from "@/components/Navbar.vue"
import Loadar2 from "@/components/Loadar2.vue"
import Hero from '@/components/Hero.vue'
import About from "@/components/About.vue"
import Feature from "@/components/Feature.vue"
import Category from "@/components/Category.vue"
import Customer from "@/components/Customer.vue"
import CustomFooter from '@/components/CustomFooter.vue'
import Participant from '@/components/Participant.vue'
import SpaceForm from "@/components/SpaceForm.vue"
export default {
    name: 'Landing',
    components: {
        Navbar,
        Loadar2,
        Hero,
        About,
        Feature,
        Category,
        Customer,
        Participant,
        CustomFooter,
        SpaceForm
    },
    data: () => ({
        lang: '',
    }),
    methods: {

    },
    mounted() {
        this.lang = "ar"
        document.documentElement.scrollTop = 0;
    }

}
</script>

<style>
.landing{
    /* background: gray; */
    height: 1500px;
}
.overflow-hidden-x {
    overflow-x: clip;
}

</style>