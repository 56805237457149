<template>
    <section class="category" id="category">
        <div class="container">
            <div class="row justify-content-center">
                <h2 class="section-title">أقسام مول هديتي الإفتراضي</h2>
            </div>
            <div class="content" style="margin-top: 30px" dir="ltr">
                <vueper-slides :touchable="false"
                :bullets="false"
                autoplay
                    ref="vueperslides1"
                    :gap="2"
                    slide-multiple
                    :breakpoints="{
                        700: { visibleSlides: 1, slideMultiple: 1 },
                        992: { visibleSlides: 2, slideMultiple: 2 },
                    }"
                    @slide="
                        $refs.vueperslides1 &&
                            $refs.vueperslides1.goToSlide(
                                $event.currentSlide.index,
                                { emit: false }
                            )
                    "
                    :visible-slides="5"
                    fixed-height="240px"
                    style="margin: 0px"
                >
                    <vueper-slide
                        v-for="(item,i) in data"
                        :key="i"
                        @click.native="
                            $refs.vueperslides1 &&
                                $refs.vueperslides1.goToSlide(i - 1)
                        "
                    >
                        <template #content>
                            <category-card :title="item.title" :image="item.image"  data-aos="zoom-out-up"  data-aos-delay="500"  data-aos-duration="1800" />
                            
                        </template>
                    </vueper-slide>
                </vueper-slides>
            </div>
        </div>
    </section>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import CategoryCard from './Cards/CategoryCard.vue'
export default {
    components: {
        VueperSlides,
        VueperSlide,
        CategoryCard
    },
    data() {
        return {
            data: [
                {
                    title: "الملابس والاحذية والاكسسوارات النسائية",
                    image: "women.svg"
                },
                {
                    title: "الملابس والاحذية والاكسسوارات الرجالية",
                    image: "suit.svg"
                },
                {
                    title: "الملابس والاحذية والاكسسوارات الولادية",
                    image: "baby.svg"
                },
                {
                    title: "المجوهرات والساعات - مستحضرات التجميل والعناية الشخصية",
                    image: "ring.svg"
                },
                {
                    title: "متاجر بيع الهدايا والتذكارات - محلات بيع الزهور والازهار الطبيعية والصناعية",
                    image: "gift.svg"
                },
                {
                    title: "المطاعم والوجبات السريعة - محلات بيع الحلويات والمخبوزات",
                    image: "pizza.svg"
                },
                {
                    title: "السوبر ماركت والبقالة",
                    image: "supermarket.svg"
                },
                {
                    title: "الاكترونيات والاجهزة الكهربائية - الاثاث والمفروشات المنزلية",
                    image: "sofa.svg"
                },
                {
                    title: "مصارف وبنوك وشركات حوالات - والشركات الخدمية - المكاتب السياحية وحجوزات الطيران",
                    image: "money.svg"
                },
                {
                    title: "متاجر منوعة - اسر منتجة ومنتجات يدوية",
                    image: "wool.svg"
                },
            ]
        };
    },
    methods: {

    },
    mounted() {
    },
}
</script>
<style>
.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:after,
.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:before {
    display: none !important;
}
.vueperslides__track{
    padding: 10px;
}

.vueperslides__arrow {
    color: var(--v-primary-base)
}
.category{
    margin: 40px 0px;
    padding: 20px 0px;
}

.category .section-title::before, .section-title::after  {
    content: "";
    position: absolute;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 0;
    height: 1px;
    width: 80px;
    background-color: var(--v-primary-base);
}
.category .section-title::after {
    right: 30%;
}
.category .section-title::before {
    bottom: -5px;
    right: 38%;
}
@media (max-width: 767px){
    .category .section-title{
        font-size: 26px;
    }
    .category .section-title::after {
        right: 20%;
    }
    .category .section-title::before {
        right: 30%;
    }
}


</style>