<template>
    <section class="hero" id="hero">
        <div class="hero-shap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="content">
                            <h1
                                class="hero-title"
                                data-aos="fade-up"
                                data-aos-duration="1200"
                            >
                                هديتي <span>Mall</span>
                            </h1>
                            <h2
                                class="hero-title2"
                                data-aos="fade-up"
                                data-aos-duration="1200"
                            >
                                استعد لرحلة لامثيل لها نحو التحول الرقمي والتفوق
                                في المستقبل
                            </h2>
                            <div class="icons" data-aos="zoom-out">
                                <div
                                    data-aos="fade-up"
                                    data-aos-duration="1500"
                                    data-aos-delay="400"
                                >
                                    <img
                                        class="version-icon"
                                        src="@/assets/img/android.svg"
                                        id="android"
                                        alt="android"
                                    />
                                    <b-tooltip target="android"
                                        >Android</b-tooltip
                                    >
                                </div>
                                <div
                                    data-aos="fade-up"
                                    data-aos-duration="1500"
                                    data-aos-delay="600"
                                >
                                    <img
                                        class="version-icon"
                                        src="@/assets/img/windows.svg"
                                        id="windows"
                                        alt="windows"
                                    />
                                    <b-tooltip target="windows"
                                        >Windows</b-tooltip
                                    >
                                </div>
                                <div
                                    data-aos="fade-up"
                                    data-aos-duration="1500"
                                    data-aos-delay="800"
                                >
                                    <img
                                        class="version-icon"
                                        src="@/assets/img/apple.svg"
                                        id="apple"
                                        alt="apple and mac"
                                    />
                                    <b-tooltip target="apple"
                                        >Apple & Mac</b-tooltip
                                    >
                                </div>
                                <div
                                    data-aos="fade-up"
                                    data-aos-duration="1500"
                                    data-aos-delay="1000"
                                >
                                    <img
                                        class="version-icon"
                                        src="@/assets/img/playstation.svg"
                                        id="playstation"
                                        alt="playstation"
                                    />
                                    <b-tooltip target="playstation"
                                        >Playstation</b-tooltip
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6"></div>
                    <ul class="hero-pertical">
                        <li class="bubble1"></li>
                        <li class="bubble2"></li>
                        <li>
                            <img src="@/assets/img/white/clothes.svg" alt="icon"  />
                        </li>
                        <li>
                            <img src="@/assets/img/white/gift.svg" alt="icon"  />
                        </li>
                        <li>
                            <img src="@/assets/img/white/market.svg" alt="icon"  />
                        </li>
                        <li>
                            <img src="@/assets/img/white/pizza.svg" alt="icon"  />
                        </li>
                        <li>
                            <img src="@/assets/img/white/ring.svg" alt="icon"  />
                        </li>
                        <li>
                            <img src="@/assets/img/white/tv.svg" alt="icon" />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="hero-image">
            <img src="@/assets/img/hero2.webp" alt="hero image" />
        </div>
    </section>
</template>

<script>

export default {
    components: {
    },
    data() {
        return {
        };
    },
    methods: {

    },
    mounted() {
    },
}
</script>
<style>
.hero{
    background-image: linear-gradient(50deg, #0C366A 0%, #279DFF 100%);
    /* height: 930px; */
    overflow: hidden;
    position: relative;
}
.hero-shap{
    background-image: url(@/assets/img/top_shape_bg6.png);
    position: absolute;
    top: 0px;
    right: 0;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
}
.hero .content{
    position: relative;
    z-index: 333;
    text-align: justify;
    max-width: 500px;
}
.hero .hero-title{
    color: var(--v-primary-base);
    font-weight: bold;
}
.hero .hero-title span{
    color: var(--v-secondary-base);
}
.hero .hero-title2{
    margin-top: 20px;
    color: var(--v-primary-base);
}
.hero .icons{
    display: flex;
    column-gap: 20px;
    margin-top: 50px
}
.hero .hero-image{
    padding-top: 150px;
}

@media (min-width: 1200px) {
    .hero .hero-image{
        padding-top: 150px;
    }
    .hero .hero-image img{
        width: 600px
    }
    .hero .content{
        padding: 280px 0 0;
    }
    .hero .hero-title{
        font-size: 50px;
    }
    .hero .hero-title2{
        font-size: 32px;
    }
    .hero .version-icon{
        width: 55px;
    }
}
@media (max-width: 1200px) and (min-width: 992px) {
    .hero .hero-image{
        padding-top: 120px;
    }
    .hero .hero-image img{
        width: 470px
    }
    .hero .content{
        padding: 220px 0 0;
    }
    .hero .hero-title{
        font-size: 40px;
    }
    .hero .hero-title2{
        font-size: 28px;
    }
    .hero .version-icon{
        width: 55px;
    }
}
@media (max-width: 992px) and (min-width: 767px) {
    .hero .hero-image{
        padding-top: 150px;
    }
    .hero .hero-image img{
        width: 350px
    }
    .hero .content{
        padding: 180px 0 0;
    }
    .hero .hero-title{
        font-size: 32px;
    }
    .hero .hero-title2{
        font-size: 24px;
        max-width: 360px;
    }
    .hero .version-icon{
        width: 50px;
    }
}
@media (max-width: 767px) {
    .hero .content{
        padding: 300px 0 0;
    }
    .hero .hero-title{
        font-size: 32px;
    }
    .hero .hero-title2{
        font-size: 22px;
        max-width: 300px;
    }
    .hero .version-icon{
        width: 50px;
    }
    .hero{
        height: 600px;
    }
    .hero .hero-image img{
        display: none !important;
    }
    .hero .version-icon{
        width: 45px;
    }
    .hero .icons{
        margin-top: 30px !important;
    }
}
@media (min-width: 576px) and (max-width: 768px){
    .hero .container{
        max-width: 700px !important;
    }
}
@media (min-width: 768px) and (max-width: 1200px){
    .hero .container {
        max-width: 950px !important;
    }
}
@media (min-width: 1200px){
    .hero .container{
        max-width: 1140px !important;
    }
}
.hero .version-icon{
    background-color: var(--v-secondary-base);
    border-radius: 50%;
    padding: 12px;
    box-shadow: 0 10px 10px rgba(0,0,0,.1);
    cursor: pointer;
    transition: all .3s;
}


.hero .version-icon:hover{
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.192);
    transition: all .3s;
    cursor: auto;
}



.tooltip-inner {
    background: var(--v-secondary-base) !important;
}

.bs-tooltip-top .arrow::before {
    border-top-color: var(--v-secondary-base) !important;
}




.hero .hero-agency .hero-pertical {
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.hero .hero-pertical li.bubble1 {
    background-image: -o-linear-gradient(40deg, #0C366A 1%, #279DFF 100%);
    background-image: linear-gradient(50deg, #0C366A 1%, #279DFF 100%);
    height: 120px;
    width: 120px;
    border-radius: 50%;
    top: -60px;
    right: 175px;
    opacity: 0.5;
    z-index: 1;
    -webkit-animation: wave 2s 0.1s infinite linear;
    animation: wave 2s 0.1s infinite linear;
}
.hero .hero-pertical li {
    position: absolute;
}
.hero .hero-pertical li.bubble2 {
    background-image: -o-linear-gradient(40deg, #0C366A 1%, #279DFF 100%);
    background-image: linear-gradient(50deg, #0C366A 1%, #279DFF 100%);
    height: 120px;
    width: 120px;
    border-radius: 50%;
    top: -80px;
    right: 140px;
    opacity: 0.1;
    z-index: 2;
    -webkit-animation: wave 3s 0.1s infinite linear;
    animation: wave 3s 0.1s infinite linear;
}

.hero .hero-pertical li.bubble3 {
    background-image: -o-linear-gradient(50deg, #279DFF 0%, #F9ECE4 100%);
    background-image: linear-gradient(40deg, #279DFF 0%, #F9ECE4 100%);
    height: 100px;
    width: 100px;
    border-radius: 50%;
    bottom: 84px;
    right: 40%;
    -webkit-animation: wave 2s 0.1s infinite linear;
    animation: wave 2s 0.1s infinite linear;
}
@keyframes wave {
	0% {
		-webkit-transform: rotateZ(0deg) translate3d(0, 3%, 0) rotateZ(0deg);
		transform: rotateZ(0deg) translate3d(0, 3%, 0) rotateZ(0deg);
	}
	100% {
		-webkit-transform: rotateZ(360deg) translate3d(0, 3%, 0) rotateZ(-360deg);
		transform: rotateZ(360deg) translate3d(0, 3%, 0) rotateZ(-360deg);
	}
}
.hero .hero-pertical img{
    width: 35px;
    opacity: .8;
}


.hero .hero-pertical li:nth-child(3) {
    bottom: 75%;
    right: 50%;
    z-index: 2;
    -webkit-animation: animetwo 40s infinite linear;
    animation: animetwo 40s infinite linear;
}

.hero .hero-pertical li:nth-child(4) {
    top: 35%;
    right: 70%;
    z-index: 2;
    -webkit-animation: animethree 40s infinite linear;
    animation: animethree 40s infinite linear;
}

.hero .hero-pertical li:nth-child(5) {
    top: 30%;
    right: 80%;
    z-index: 2;
    -webkit-animation: animethree 40s infinite linear;
    animation: animethree 40s infinite linear;
}


.hero .hero-pertical li:nth-child(6) {
    top: 40%;
    right: 90%;
    z-index: 2;
    -webkit-animation: animeone 40s infinite linear;
    animation: animeone 40s infinite linear;
}
.hero .hero-pertical li:nth-child(7) {
    bottom: 80%;
    right: 60%;
    z-index: 2;
    -webkit-animation: animeone 40s infinite linear;
    animation: animeone 40s infinite linear;
}
.hero .hero-pertical li:nth-child(8) {
    top: 50%;
    right: 65%;
    z-index: 2;
    -webkit-animation: animeone 40s infinite linear;
    animation: animeone 40s infinite linear;
}


@keyframes animeone {
	0% {
		-webkit-transform: translate(0) rotate(0deg);
		transform: translate(0) rotate(0deg);
	}
	20% {
		-webkit-transform: translate(-40px, -20px) rotate(10deg);
		transform: translate(-40px, -20px) rotate(10deg);
	}
	40% {
		-webkit-transform: translate(-70px, -50px) rotate(20deg);
		transform: translate(-70px, -50px) rotate(20deg);
	}
	60% {
		-webkit-transform: translate(-90px, -70px) rotate(20deg);
		transform: translate(-90px, -70px) rotate(20deg);
	}
	80% {
		-webkit-transform: translate(-70px, -50px) rotate(10deg);
		transform: translate(-70px, -50px) rotate(10deg);
	}
}

@keyframes animetwo {
	0% {
		-webkit-transform: translate(0) rotate(0deg);
		transform: translate(0) rotate(0deg);
	}
	20% {
		-webkit-transform: translate(-40px, 1px) rotate(-36deg);
		transform: translate(-40px, 1px) rotate(-36deg);
	}
	40% {
		-webkit-transform: translate(-80px, -12px) rotate(-72deg);
		transform: translate(-80px, -12px) rotate(-72deg);
	}
	60% {
		-webkit-transform: translate(-90px, -22px) rotate(-108deg);
		transform: translate(-90px, -22px) rotate(-108deg);
	}
}
@keyframes animethree {
	0% {
		-webkit-transform: translate(0) rotate(0deg);
		transform: translate(0) rotate(0deg);
	}
	20% {
		-webkit-transform: translateY(-33px) rotate(-20deg);
		transform: translateY(-33px) rotate(-20deg);
	}
	40% {
		-webkit-transform: translateY(-81px) rotate(-40deg);
        transform: translateY(-81px) rotate(-40deg);
	}
	60% {
		-webkit-transform: translateY(-110px) rotate(-60deg);
		transform: translateY(-110px) rotate(-60deg);
	}
	80% {
		-webkit-transform: translateY(-130px) rotate(-80deg);
        transform: translateY(-130px) rotate(-80deg);
	}
}

</style>