import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework'
Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        defaultTheme: 'light',
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#0C366A',
                secondary: '#279DFF',
                accent: '#F9ECE4',
            },
        },
    },
});



