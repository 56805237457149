<template>
    <section class="feature" id="feature">
        <div class="bg-shap">
            <img
                src="@/assets/img/3 (2).png"
                alt="astriol shape bg"
                style="visibility: visible"
            />
        </div>

        <div class="container">
            <div class="row justify-content-center">
                <h2 class="section-title">ميزاتنا</h2>
                <div class="row justify-content-center align-items-center">
                    <div class="col-lg-4 col-md-10">
                        <div
                            class="features-box text-end"
                            data-aos="fade-left"
                            data-aos-duration="1500"
                            data-aos-delay="600"
                        >
                            <div class="pe-3 me-5">
                                <h5 class="f-15 feature-title">
                                    تقنيــات الواقــع الافتراضــي (VR)
                                </h5>
                                <p class="text-muted mt-3">
                                    يســتخدم المــول تقنيــات الواقــع
                                    الافتراضــي لتوفيــر تجربــة تســوق
                                    واقعيــة، حيث يمكن للمستخدمين استكشاف
                                    المنتجات بمظهر واقعي.
                                </p>
                            </div>
                            <div class="features-icon float-start mr-2">
                                <img src="@/assets/img/vr.svg" alt="icon" />
                            </div>
                        </div>

                        <div
                            class="features-box text-end mt-5"
                            data-aos="fade-left"
                            data-aos-duration="1500"
                            data-aos-delay="800"
                        >
                            <div class="pe-3 me-5">
                                <h5 class="f-15 feature-title">
                                    مسـاحات افتراضيـة مخصصـة
                                </h5>
                                <p class="text-muted mt-3">
                                    يتيـح المـول للعلامـات التجاريـة والبائعين
                                    إنشـاء مسـاحات افتراضيـة مخصصة لعرض منتجاتهم
                                    وبناء هويتهم البصرية.
                                </p>
                            </div>
                            <div class="features-icon float-start mr-2">
                                <img src="@/assets/img/space.svg"  alt="icon" />
                            </div>
                        </div>

                        <div
                            class="features-box text-end mt-5"
                            data-aos="fade-left"
                            data-aos-duration="1500"
                            data-aos-delay="1000"
                        >
                            <div class="pe-3 me-5">
                                <h5 class="f-15 feature-title">
                                    تجربـة تفاعليـة للدفـع
                                </h5>
                                <p class="text-muted mt-3">
                                    يمكـن للمسـتخدمين الدفـع بطـرق تفاعليـة
                                    وآمنـة باسـتخدام بطاقـات الائتمـان أو وسـائل
                                    الدفع الأخرى المدعومة.
                                </p>
                            </div>
                            <div class="features-icon float-start mr-2">
                                <img src="@/assets/img/pay.svg"  alt="icon" />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="text-center">
                            <div
                                data-aos="zoom-in"
                                data-aos-duration="2000"
                                data-aos-delay="800"
                                class="main-img"
                            >
                                <v-img
                                    src="@/assets/img/feature.webp"
                                    :aspect-ratio="16 / 9"
                                    alt="mokup"
                                    class="main-image"
                                ></v-img>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-10">
                        <div
                            class="features-box text-start mt-5"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="600"
                        >
                            <div class="features-icon float-end ml-2">
                                <img src="@/assets/img/community.svg"  alt="icon" />
                            </div>
                            <div class="ps-3 ms-5">
                                <h5 class="f-15 feature-title">
                                    تجربـة تسـوق اجتماعيـة
                                </h5>
                                <p class="text-muted mt-3">
                                    يسـمح المـول للمسـتهلكين بالتفاعـل مـع
                                    بعضهـم البعـض أثنـاء التسـوق، ممـا يعكـس
                                    تجربة التسوق الاجتماعية التقليدية.
                                </p>
                            </div>
                        </div>

                        <div
                            class="features-box text-start mt-5"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="800"
                        >
                            <div class="features-icon float-end ml-2">
                                <img src="@/assets/img/user-talk.svg" alt="icon"  />
                            </div>
                            <div class="ps-3 ms-5">
                                <h5 class="f-15 feature-title">
                                    التواصـل مـع العلامـات التجاريـة والبائعيـن
                                </h5>
                                <p class="text-muted mt-3">
                                    يمكـن للمسـتخدمين التواصل مـع العلامات
                                    التجارية والبائعين بشـكل مباشر ومتواصل
                                    للحصول على مزيد من المعلومات حول المنتجات
                                    والخدمات.
                                </p>
                            </div>
                        </div>

                        <div
                            class="features-box text-start mt-5"
                            data-aos="fade-right"
                            data-aos-duration="1500"
                            data-aos-delay="1000"
                        >
                            <div class="features-icon float-end ml-2">
                                <img src="@/assets/img/clothes.svg" alt="icon"  />
                            </div>
                            <div class="ps-3 ms-5">
                                <h5 class="f-15 feature-title">
                                    المعاينـة الافتراضيـة للملابـس
                                </h5>
                                <p class="text-muted mt-3">
                                    يمكـن للـزوار تجربـة الملابـس افتراضيـا
                                    باسـتخدام تقنيـة تغييـر الألبسـة، حيـث
                                    يمكنهم اختيار الملابس واستعراض كيف ستبدو
                                    عليهم.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    components: {

    },
    data() {
        return {
        };
    },
    methods: {

    },
    mounted() {
    },
}
</script>
<style>

.feature{
    margin: 50px 0px;
    padding: 50px 0px;
    /* background: #dedede4d; */
}
.feature .bg-shap{
    position: absolute;
    top: 55%;
    left: 0;
    z-index: 0;
}

.feature .feature-title{
    color: var(--v-secondary-base)
}
.feature .features-icon img{
    width: 40px;
}
.feature .features-icon{
    border: 1px solid transparent;
    padding: 7px;
    transition: all .3s ease;
}
.feature .features-box:hover .features-icon {
    background-color: var(--v-accent-base);
    padding: 7px;
    border-radius: 50%;
    border: 1px solid var(--v-secondary-base)
}
.feature .features-box{
    display: flex;
    align-items: start;
    z-index: 99999;
    position: relative;
}

.feature .main-img{
    display: flex;
    justify-content: center;
    visibility: visible;
    animation: ImgBounce1 3s ease-in-out infinite alternate;
    animation-delay: 2s;
}
@keyframes ImgBounce1 {
0%{
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
}
100% {
    -webkit-transform: translateY(-25px);
    -moz-transform: translateY(-25px);
    -ms-transform: translateY(-25px);
    -o-transform: translateY(-25px);
    transform: translateY(-25px);
}
}
@media (min-width: 992px) {
    .feature .main-image{
        min-width: 800px !important
    }
}
@media (max-width: 991px) {
    .feature .main-image{
        width: 600px !important
    }
    .feature .bg-shap{
        display: none !important;
    }
    .feature{
        background-color: #f2f2f88c;
    }
}
@media (max-width: 680px) {
    .feature .text-end {
        margin-left: 130px;
        margin-right: 100px;
    }
    .feature .text-start {
        margin-right: 130px;
        margin-left: 100px;
    }
    .feature .feature-title{
        font-size: 17px;
    }
    .feature p{
        font-size: 14px;
    }
}
</style>